const ourSolutionsSection = document.getElementsByClassName(
  'our-solutions-section'
)[0];
if (ourSolutionsSection) {
  const ourSolutionsListArr = ourSolutionsSection.querySelectorAll(
    '.our-solutions-section__item-list'
  );
  const ourSolutionsButtons = ourSolutionsSection.querySelector(
    '.our-solutions-section__bottom-buttons'
  );

  ourSolutionsListArr.forEach((list, idx) => {
    const itemsArr = list.querySelectorAll('li');
    const listHeight = list.clientHeight;
    /*if (itemsArr.length > 4) {
      list.style.maxHeight = `${listHeight / 2}px`;
    }*/

    if (itemsArr.length <= 4) {
      list.classList.add('full-size');
    }

    console.log(idx, ourSolutionsListArr.length);

    if (idx === ourSolutionsListArr.length - 1 && itemsArr.length <= 2) {
      ourSolutionsButtons.classList.add('margin-list');
    }
  });
}
