import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { autoPlacement, computePosition, flip, offset } from '@floating-ui/dom';
import SimpleBar from 'simplebar';

if (document.getElementsByClassName('services-section__slider')[0]) {
  const servicesSlidersArr = document.querySelectorAll(
    '.services-section__slider'
  );
  servicesSlidersArr.forEach((sliderEl, sliderIdx) => {
    const servicesOpenBtnArr = sliderEl.querySelectorAll(
      '.services-section__item-icon'
    );
    const servicesTooltipArr = sliderEl.querySelectorAll(
      '.services-section__item-tooltip'
    );
    const servicesTooltipTextArr = sliderEl.querySelectorAll(
      '.services-section__item-tooltip-text'
    );

    const pagination = sliderEl.querySelector('.swiper-pagination');
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let countLastVisibleSlide = 0;

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const servicesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      /*navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },*/
      breakpoints: {
        390: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        451: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 4,
        },
        901: {
          spaceBetween: 16,
          slidesPerView: 5,
        },
        1025: {
          spaceBetween: 16,
          slidesPerView: 6,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 6,
        },
      },
      /*on: {
        init: function () {
          getLastVisibleIndex();
        },
      },*/
    });

    swiperObserver(servicesSwiper);

    function getLastVisibleIndex() {
      const activeIndex = servicesSwiper.activeIndex;
      const slidesPerView = servicesSwiper.params.slidesPerView;
      const visibleSlides = Math.min(
        servicesSwiper.slides.length,
        slidesPerView
      );
      const lastVisibleIndex = activeIndex + visibleSlides - 1;
      /*console.log('Индекс последнего видимого слайда:', lastVisibleIndex);
      console.log(slides[lastVisibleIndex]);*/
      countLastVisibleSlide = lastVisibleIndex;
    }

    servicesSwiper.on('slideChange', function () {
      getLastVisibleIndex();
    });

    setTimeout(function () {
      getLastVisibleIndex();
    }, 100);

    slides.forEach((slide, i) => {});

    function positionTooltip(button, tooltip, idx) {
      let placement = 'right-start';
      if (idx === countLastVisibleSlide || idx === countLastVisibleSlide + 1) {
        if (window.innerWidth >= 551) {
          placement = 'left-start';
        } else {
          if (window.innerWidth >= 390) {
            placement = 'bottom';
          }
        }
      }
      computePosition(button, tooltip, {
        middleware: [offset(-10)],
        placement: placement,
      }).then(({ x, y }) => {
        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    }

    function openTooltip(button, tooltip, idx) {
      tooltip.classList.add('active');
      positionTooltip(button, tooltip, idx);
    }

    servicesOpenBtnArr.forEach((btn, i) => {
      btn.addEventListener('mouseenter', () => {
        openTooltip(btn, servicesTooltipArr[i], i);
      });

      slides[i].addEventListener('mouseleave', (event) => {
        servicesTooltipArr[i].classList.remove('active');

        /*servicesTooltipArr[i].addEventListener('mouseenter', () => {
          openTooltip(btn, servicesTooltipArr[i], i);
        });*/
      });

      btn.addEventListener('click', () => {
        if (servicesTooltipArr[i].classList.contains('active')) {
          servicesTooltipArr[i].classList.remove('active');
        } else {
          openTooltip(btn, servicesTooltipArr[i], i);
        }
      });
    });

    for (let i = 0; i < servicesTooltipTextArr.length; i++) {
      new SimpleBar(servicesTooltipTextArr[i]);
    }
  });
}
