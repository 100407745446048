import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('offer-services-section__slider')[0]) {
  const offerServicesListArr = document.querySelectorAll(
    '.offer-services-section__list'
  );
  /*const offerServicesSliderButtonsArr = document.querySelectorAll(
    '.offer-services-section__slider-buttons'
  );*/
  const offerServicesSlidersArr = document.querySelectorAll(
    '.offer-services-section__slider'
  );
  offerServicesSlidersArr.forEach((sliderEl, sliderIdx) => {
    const pagination = sliderEl.querySelector('.swiper-pagination');
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let effect = 'fade';
    if (window.innerWidth <= 768) {
      effect = 'slide';
    }

    let pauseOnMouseEnter = window.innerWidth > 1024;

    const offerServicesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, EffectFade, Pagination],
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },
      allowTouchMove: true,
      pagination: {
        el: pagination,
        clickable: true,
      },
      /*navigation: {
        prevEl:
          offerServicesSliderButtonsArr[sliderIdx].querySelector(
            '.slider-btn.prev'
          ),
        nextEl:
          offerServicesSliderButtonsArr[sliderIdx].querySelector(
            '.slider-btn.next'
          ),
      },*/
      effect: effect,

      breakpoints: {
        320: {
          /*effect: 'slide',*/
        },
      },
      on: {
        slideChange: function () {
          const activeIndex = this.activeIndex;
          updateActiveTab(activeIndex);
        },
      },
    });

    swiperObserver(offerServicesSwiper);

    function updateActiveTab(activeIndex) {
      const tabs = offerServicesListArr[sliderIdx].querySelectorAll(
        '.offer-services-section__list-item'
      );

      for (let i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove('active');
      }

      tabs[activeIndex].classList.add('active');
    }

    const offerServicesListItemsArr = offerServicesListArr[
      sliderIdx
    ].querySelectorAll('.offer-services-section__list-item');
    offerServicesListItemsArr.forEach((itemEl, itemIdx) => {
      itemEl.addEventListener('click', () => {
        for (let i = 0; i < offerServicesListItemsArr.length; i++) {
          offerServicesListItemsArr[i].classList.remove('active');
        }

        offerServicesSwiper.slideTo(itemIdx);
        itemEl.classList.add('active');
      });
    });
  });
}
