const aboutContainer = document.querySelector('.about-section__container');

if (aboutContainer) {
  const aboutItemsArr = aboutContainer.querySelectorAll('.about-section__item');
  let activeIndex = -1;
  let autoplayInterval;
  const intervalValue = 3000;
  let started = false;

  function openItem(item) {
    item.classList.add('active');
  }

  function closeItems() {
    aboutItemsArr.forEach((item) => item.classList.remove('active'));
  }

  function startAutoplay() {
    autoplayInterval = setInterval(() => {
      closeItems();
      activeIndex++;
      if (activeIndex >= aboutItemsArr.length) {
        activeIndex = 0;
      }
      openItem(aboutItemsArr[activeIndex]);
    }, intervalValue);
  }

  function pauseAutoplay() {
    clearInterval(autoplayInterval);
  }

  function resumeAutoplay() {
    startAutoplay();
  }

  aboutItemsArr.forEach((item, i) => {
    const btn = item.querySelector('.about-section__item-btn');

    item.addEventListener('click', () => {
      pauseAutoplay();
      closeItems();
      activeIndex = i;
      openItem(item);
    });
  });

  aboutContainer.addEventListener('mouseenter', pauseAutoplay);
  aboutContainer.addEventListener('mouseleave', resumeAutoplay);

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.85,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!started) {
        if (entry.isIntersecting) {
          setTimeout(() => {
            activeIndex = 0;
            openItem(aboutItemsArr[0]);
            startAutoplay();
            started = true;
          }, 300);
        } else {
          pauseAutoplay();
        }
      }
    });
  }, observerOptions);

  observer.observe(aboutContainer);
}
