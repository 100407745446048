import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import SimpleBar from 'simplebar';

if (document.getElementsByClassName('areas-section__slider')[0]) {
  const areasSlidersArr = document.querySelectorAll('.areas-section__slider');
  const areasListArr = document.querySelectorAll('.areas-section__list');
  const areasSliderButtonsArr = document.querySelectorAll(
    '.areas-section__slider-buttons'
  );
  areasSlidersArr.forEach((sliderEl, sliderIdx) => {
    const areasList = areasListArr[sliderIdx];
    const areasItems = areasList.querySelectorAll('.areas-section__list-item');
    const pagination = sliderEl.querySelector('.swiper-pagination');
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 3) {
      loop = true;
      loopedSlides = slides.length * 3;
    }*/

    let pauseOnMouseEnter = window.innerWidth > 1024;

    let autoplay = {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: pauseOnMouseEnter,
    };

    if (window.innerWidth > 768) {
      autoplay = false;
    }

    const areasSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      pagination: {
        el: pagination,
        clickable: true,
      },
      allowTouchMove: true,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },
      on: {
        slideChange: function () {
          const activeSlideIndex = this.activeIndex;
          /*areasItems.forEach((item, i) => {
            if (i === activeSlideIndex) {
              item.classList.add('active');
            } else {
              item.classList.remove('active');
            }
          });*/

          updateActiveTab(activeSlideIndex);
        },
      },
      breakpoints: {
        1025: {
          allowTouchMove: false,
        },
      },
    });

    swiperObserver(areasSwiper);

    const simpleBar = new SimpleBar(areasList);

    function updateActiveTab(activeIndex) {
      const tabs = areasItems;

      for (let i = 0; i < tabs.length; i++) {
        tabs[i].classList.remove('active');
      }

      tabs[activeIndex].classList.add('active');

      // Получить элемент, до которого нужно прокрутить
      var targetElement = tabs[activeIndex];

      // Получить позицию элемента, до которого нужно прокрутить
      var targetPosition = targetElement.offsetTop;

      // Получить текущее положение прокрутки
      var currentPosition = simpleBar.getScrollElement().scrollTop;

      // Прокрутить содержимое плавно до элемента
      var animationDuration = 200;
      var startTime;

      function scrollToTarget(timestamp) {
        if (!startTime) startTime = timestamp;
        var progress = timestamp - startTime;
        var easeInOutQuad = function (t) {
          return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };
        var ease = easeInOutQuad(Math.min(progress / animationDuration, 1));
        simpleBar.getScrollElement().scrollTop =
          currentPosition + ease * (targetPosition - currentPosition);
        if (progress < animationDuration) {
          requestAnimationFrame(scrollToTarget);
        }
      }

      requestAnimationFrame(scrollToTarget);
    }

    areasItems.forEach((item, i) => {
      item.addEventListener('click', () => {
        areasSwiper.slideTo(i);
      });
    });
  });
}
