const feauteresSection = document.getElementsByClassName('features-section')[0];
if (feauteresSection) {
  const itemTitlesArr = document.querySelectorAll(
    '.features-section__item-title'
  );

  function handleResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 640) {
      resetMinHeightForColumns(itemTitlesArr);
    } else {
      setMinHeightForColumns(itemTitlesArr, 2);
    }
  }

  window.addEventListener('resize', handleResize);

  handleResize();
}

function setMinHeightForColumns(titlesArr, count) {
  const itemTitlesArr = titlesArr;

  const numColumns = count;

  function setMinHeightForTitlesInRow(row) {
    let maxHeight = 0;
    row.forEach((item) => {
      const height = item.clientHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });
    row.forEach((item) => {
      item.style.minHeight = maxHeight + 'px';
    });
  }

  const rows = [];
  let currentRow = [];
  let currentColumn = 0;
  itemTitlesArr.forEach((item) => {
    if (currentColumn >= numColumns) {
      rows.push(currentRow);
      currentRow = [];
      currentColumn = 0;
    }
    currentRow.push(item);
    currentColumn++;
  });

  if (currentRow.length) {
    rows.push(currentRow);
  }

  rows.forEach((row) => {
    setMinHeightForTitlesInRow(row);
  });
}

function resetMinHeightForColumns(titlesArr) {
  titlesArr.forEach((item) => {
    item.style.minHeight = '';
  });
}
